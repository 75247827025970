import React, { useContext, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { UserLanguageContext } from 'pages/_app'
import _ from 'lodash'
import axios from 'axios'
import Cookies from 'universal-cookie'
import Welcome from 'components/view/my-stickers/Welcome'
import { API_HOST, COOKIE_OPTION, STIPOP_DOMAIN } from 'common/common'
import mixpanel from 'mixpanel-browser'

const index = () => {
  const router = useRouter()
  const lang = useContext(UserLanguageContext)
  const cookies = new Cookies()
  const [welcome, setWelcome] = useState(false)
  const [tracking, setTracking] = useState(false)

  const token = cookies.get('stipop-token', COOKIE_OPTION)

  useEffect(() => {
    if (token) {
      axios
        .get(`${API_HOST}/v4/mypage`, {
          headers: {
            // apikey: process.env.API_KEY,
            token: token,
          },
        })
        .then(({ data }) => {
          mixpanel.identify(data.body.userInfo.userId)
          localStorage.setItem(
            'stipop-account',
            JSON.stringify({
              ...data.body.userInfo,
              token: token,
            })
          )
          setTracking(true)
        })
    } else {
      const token2 = cookies.get('stipop-token', COOKIE_OPTION)
      if (!token2) {
        localStorage.removeItem('stipop-account')
        router.push(`${STIPOP_DOMAIN}/signin`)
      }
    }
  }, [])

  useEffect(() => {
    if (tracking) {
      axios
        .put(`${API_HOST}/v4/mypage/popup/account`, '', {
          headers: {
            // apikey: process.env.API_KEY,
            token: token,
          },
        })
        .then(({ data }) => {
          if (data.body === true) {
            setWelcome(true)
          } else {
            if (localStorage.getItem('stipop-account')) {
              router.push(`/my-stickers`)
            }
          }
        })
    }
  }, [tracking])

  return <>{welcome && <Welcome welcome={welcome} setWelcome={setWelcome} />}</>
}

index.getInitialProps = async context => {
  const meta = {
    title: `Stipop | A complete sticker API and SDK for all apps`,
    description:
      'Strenghen community and increase retention with Stipop sticker API and SDK solution. Add 150,000+ unique stickers within a day with pre-built UI components for sticker search and store. Get started for free today.',
    url: `https://account.stipop.com`,
  }
  return { meta }
}

export default index
