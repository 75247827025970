import React, { useRef } from 'react'
import styled from 'styled-components'
import SpaceResponsive from 'constants/SpaceResponsive'
import { useRouter } from 'next/router'

const Welcome = props => {
  const { welcome, setWelcome } = props
  const welcomeRef = useRef()
  const router = useRouter()

  return (
    <WelcomeWrapper
      onClick={e => {
        if (!welcomeRef.current.contains(e.target)) {
          setWelcome(false)
          router.push(`/my-stickers`)
        }
      }}
    >
      <WelcomeBox ref={welcomeRef}>
        <SpaceResponsive height={60} />
        <img
          src="https://img.stipop.io/image/account/welcome-image.png"
          alt=""
        />
        <SpaceResponsive height={30} />
        <Title style={{ height: '43px', fontSize: '30px', fontWeight: '600' }}>
          Welcome to Stipop!
        </Title>
        <SpaceResponsive height={35} />
        <Desc>
          Your membership account is now active. <br />
          Go to the <span style={{ fontWeight: '600' }}>Apps</span> page and
          start by setting up Stipop for Chrome and iMessage.
        </Desc>
        <SpaceResponsive height={53} />
        <SetUpButton
          onClick={() => {
            router.push(`/apps`)
          }}
        >
          Set up Chrome and iMessage
        </SetUpButton>
        <SpaceResponsive height={16.5} />
        <Close
          onClick={() => {
            router.push(`/my-stickers`)
          }}
        >
          I'll check it later
        </Close>
      </WelcomeBox>
    </WelcomeWrapper>
  )
}

export default Welcome

const WelcomeWrapper = styled.div`
  width: 100vw;
  height: 100vw;
  background-color: rgba(164, 164, 164, 0.6);
  position: fixed;
  top: 0;
  left: 0;
`

const WelcomeBox = styled.div`
  width: 566px;
  height: 603px;
  background-color: #fff;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid #e5eaf2;
  border-radius: 34px;
  box-shadow: 0 17px 28px 1px rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 164px;
    height: 157px;
    padding-right: 20px;
    /* box-sizing: border-box; */
  }
`

const Title = styled.div`
  font-family: Poppins, sans-serif;
  height: 38px;
  font-size: 28px;
  font-weight: 600;
  line-height: 1.32;
  letter-spacing: 0.22px;
  color: #0e0f19;
`

const Desc = styled.div`
  width: 434px;
  height: 72px;
  font-family: Poppins, sans-serif;
  font-size: 18px;
  line-height: 1.33;
  color: #0e0f19;
  text-align: center;
`

const SetUpButton = styled.button`
  width: 358px;
  height: 54px;
  border-radius: 14px;
  background-color: #ff4500;
  border: none;
  color: #fff;
  font-size: 18px;
  font-family: Poppins, sans-serif;

  &:hover {
    cursor: pointer;
  }
`

const Close = styled.div`
  height: 24px;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  line-height: 1.71;
  color: #838383;
  cursor: pointer;

  &:hover {
    color: #ff4500;
  }
`
